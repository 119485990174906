@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

.bg-world {
  background-color: red;
}

.auth-lottie {
  width: 400px;
  height: 500px;
}

.app:has(.auth-container) {
  padding: 0px;
}

.auth-container {
  height: 100vh;
  background: none !important;
}

.brand-title {
  font-size: 4.5rem;
}

.brand-subtitle {
  font-size: 2.5rem;
}

.auth-primary-col {
  padding: 4rem 0 0 4rem;
  /* background-color: red !important; */
  background: linear-gradient(223.26deg, #373b44 1.31%, #2762f0 99.47%);
  border-radius: 0 0 18rem 0;
}

body {
  background-color: none !important;
}

.color-white {
  color: white;
}

.auth-secondary-col-container {
  position: relative;
}

.auth-secondary-col {
  position: absolute;
  border-radius: 1rem;
  width: 100%;
  left: -25%;
  top: 4rem;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  gap: 3rem;
}

.auth-secondary-col .title {
  font-size: 2.25rem;
}

.auth-form {
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

/* .w-full {
  width: 100%;
} */

.auth-btn {
  max-width: 100% !important;
  font-weight: 500 !important;
}

.flex-col-1 {
  display: flex !important;
  flex-direction: column;
  gap: 1rem;
}

.p-0 {
  padding: 0 !important;
}

.page-name {
  /* font-weight: 500; */
  font-size: 1rem;
  width: 100%;
  justify-content: end;
}

/* .page-text-link {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
} */
.page-text-link .auth-btn {
  color: #2762f0;
  font-weight: 500;
  background-color: white;
  cursor: pointer;
}

.color-primary {
  color: #2762f0;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.auth-subtitle {
  font-size: 1.25rem;
  margin: 0 auto;
}

.m-0 {
  margin: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.avatar-text {
  display: flex;
  background: #fafbfd;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
}

.upload-lab-result-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.upload-file-title {
  font-size: 1.5rem;
  font-weight: 400;
}

@media all and (max-width: 576px) {
  .auth-lottie-container {
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
  }

  .auth-lottie-container iframe {
    display: flex;
    height: 300px;
    justify-content: center;
    width: 100%;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .auth-lottie {
    width: 100px;
    height: 100px;
  }

  .brand-title {
    font-size: 2rem;
  }

  .brand-subtitle {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 577px) and (max-width: 768px) {
  .auth-lottie {
    width: 200px;
    height: 200px;
  }

  .brand-title {
    font-size: 2.5rem;
  }
  .brand-subtitle {
    font-size: 1.75rem;
  }
}

@media all and (max-width: 1200px) {
  .auth-primary-col {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 6rem 0;
    height: fit-content;
  }

  .auth-secondary-col {
    position: unset;
    gap: 1rem;
    margin: 0 !important;
  }

  .auth-secondary-col-wrapper {
    margin: 1rem !important;
  }
}

@media all and (min-width: 769px) and (max-width: 992px) {
  .auth-lottie {
    width: 225px;
    height: 225px;
  }

  .brand-title {
    font-size: 3rem;
  }
  .brand-subtitle {
    font-size: 2rem;
  }
}

@media all and (min-width: 993px) and (max-width: 1200px) {
  .auth-lottie {
    width: 300px;
    height: 300px;
  }

  .brand-title {
    font-size: 3.5rem;
  }
  .brand-subtitle {
    font-size: 2rem;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #000000;
}

.waitlist-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Poppins", sans-serif !important;
  /* margin: 0; */
  padding: 0;
  color: #333;
}

.waitlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #2762f0;
}

.login-link {
  text-decoration: none;
  color: #2762f0;
  font-weight: 600;
}

.waitlist-h1 {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #2762f0, #1e40af);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(39, 98, 240, 0.3);
}

.waitlist-h2 {
  color: #2762f0;
  font-size: 2rem;
  margin: 2rem 0;
}

.waitlist-h3 {
  color: #333;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.waitlist-form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.waitlist-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.waitlist-email-input,
.waitlist-textarea,
.waitlist-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
}

.form-hint {
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
}

.submit-button {
  background-color: #2762f0;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1e4fc7;
}

.testimonial {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
  /* background-color: #f0f0f0; */
  border-radius: 4px;
}

.faq-answer {
  padding: 10px;
  display: none;
}

.waitlist-footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
}

.cta-button {
  display: inline-block;
  background-color: #2762f0;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 600;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #1e4fc7;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-option {
  display: flex;
  align-items: center;
}

.radio-option input[type="radio"] {
  margin-right: 10px;
}

.button-container {
  text-align: center;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.disable-link {
  opacity: 0.5;
  /* pointer-events: unset; */
  cursor: not-allowed;
}

.demo-section {
  text-align: center;
  margin-bottom: 2rem;
}

.demo-text {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.demo-image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.demo-image:hover {
  transform: scale(1.05);
}

.pricing-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.pricing-options {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pricing-option {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(33% - 20px);
  margin-bottom: 20px;
}

.option-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.option-description {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .pricing-option {
    width: 100%;
  }
}

.waitlist-footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
}

.footer-links {
  margin-top: 10px;
}

.footer-link {
  color: #2762f0;
  text-decoration: none;
  font-weight: 600;
}

.footer-link:hover {
  text-decoration: underline;
}
